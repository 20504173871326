<template>
  <div class="about">
    <h1>Om Barnens bilbingo</h1>
    <p class="mobile-padding">
      Denna webpplats skapades av mig (<a href="https://se.linkedin.com/in/gustavjappevik" title="Visa profil på LinkedIn">Gustav Jappevik</a>) för att mina barn på ett enkelt sätt skulle kunna spela Bilbingo på våra bilresor.
      <br><br>
      Webbplatsen innehåller ingen reklam och är säker att använda för barn.
      <br><br>
      Om du har förslag på fler rutor till bingot eller har andra frågor och funderingar får du gärna skicka ett mail till gustav@barnensbilbingo.se.
    </p>
  </div>
</template>
